import React from "react";
import getResizedUrl from "~/components/core/elements/image/resize-url";

const getHomepageMetadata = (
  keywords,
  url,
  policy,
  description,
  title,
  ogImage,
  contentType
) => {
  const metaKeyPrefix = `${contentType}-meta`;

  // Schema
  const schemaMetadata = {
    "@context": "http://schema.org",
    "@type": "CollectionPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url
    },
    headline: title,
    description,
    image: [
      getResizedUrl(
        "https://www.washingtonpost.com/pb/resources/img/twp-social-share.png",
        { w: 1484 }
      )
    ],
    isAccessibleForFree: true,
    hasPart: {
      "@type": "WebPageElement",
      isAccessibleForFree: true,
      cssSelector: ".paywall"
    },
    publisher: {
      "@type": "NewsMediaOrganization",
      name: "The Washington Post",
      url,
      ethicsPolicy: policy,
      masthead:
        "https://www.washingtonpost.com/policies-and-standards/masthead/",
      missionCoveragePrioritiesPolicy: policy,
      diversityPolicy: policy,
      correctionsPolicy: policy,
      verificationFactCheckingPolicy: policy,
      unnamedSourcesPolicy: policy,
      actionableFeedbackPolicy: policy,
      foundingDate: "1877-12-06",
      ownershipFundingInfo: policy,
      diversityStaffingReport: "http://asne.org/newsroom_diversitysurvey",
      refLocalNationalRequirements: null,
      logo: {
        "@type": "ImageObject",
        url: "https://www.washingtonpost.com/pb/resources/img/thewashingtonpost-black-400x60.png"
      }
    }
  };

  return [
    // Standard
    <meta
      httpEquiv="X-UA-Compatible"
      content="IE=edge,chrome=1"
      key={`${metaKeyPrefix}-httpequiv`}
    />,
    <meta charSet="utf-8" key={`${metaKeyPrefix}-charset`} />,
    <meta
      id="viewport"
      name="viewport"
      content="width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=0.5, maximum-scale=2.0"
      key={`${metaKeyPrefix}-viewport`}
    />,
    <meta
      name="description"
      content={description}
      key={`${metaKeyPrefix}-description`}
    />,
    <meta
      name="referrer"
      content="unsafe-url"
      key={`${metaKeyPrefix}-referrer`}
    />,
    <meta
      name="keywords"
      content={keywords}
      key={`${metaKeyPrefix}-keywords`}
    />,
    <meta
      name="news_keywords"
      content={keywords}
      key={`${metaKeyPrefix}-news_keywords`}
    />,
    <meta
      name="twitter:site"
      value="@WashingtonPost"
      key={`${metaKeyPrefix}-twitter:site`}
    />,
    <meta
      name="twitter:card"
      content="summary_large_image"
      key={`${metaKeyPrefix}-twitter:card`}
    />,
    <meta property="og:type" content="" key={`${metaKeyPrefix}-og:type`} />,
    <meta
      property="og:site_name"
      content="Washington Post"
      key={`${metaKeyPrefix}-og:site_name`}
    />,
    <meta name="magnet" content="" key={`${metaKeyPrefix}-magnet`} />,
    <meta
      property="fb:app_id"
      content="41245586762"
      key={`${metaKeyPrefix}-fb:app_id`}
    />,
    <meta
      property="fb:admins"
      content="4403963"
      key={`${metaKeyPrefix}-fb:admins`}
    />,
    <meta
      property="fb:admins"
      content="500835072"
      key={`${metaKeyPrefix}-fb:admins_2`}
    />,
    // Schema
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaMetadata) }}
      key={`${contentType}-script-schema`}
    />,
    // More
    <meta name="external" content="true" key={`${metaKeyPrefix}-external`} />,
    // Title
    <title key={`${contentType}-title`}>{title}</title>,
    // eslint-disable-next-line react/no-unknown-property
    <link property="og:url" href={url} key={`${contentType}-link-og:url`} />,
    <meta
      property="og:image"
      content={ogImage}
      itemProp="image"
      key={`${metaKeyPrefix}-og:image`}
    />,
    <meta
      property="og:title"
      content={title}
      key={`${metaKeyPrefix}-og:title`}
    />,
    <meta
      property="og:description"
      content={description}
      key={`${metaKeyPrefix}-og:description`}
    />,
    <meta
      name="robots"
      content="index,follow"
      key={`${metaKeyPrefix}-robots`}
    />,
    <meta name="theme" content="normal" key={`${metaKeyPrefix}-theme`} />,
    <meta name="audio_url" content="" key={`${metaKeyPrefix}-audio_url`} />,
    <meta
      name="google-site-verification"
      content="6Bi3yUCN2g3lzvqapLfrbgkQxob5YCjmZidGa2qiM4g"
      key={`${metaKeyPrefix}-google-site-verification`}
    />
  ];
};

export default getHomepageMetadata;
