import React from "react";
import PropTypes from "prop-types";
import NextHead from "next/head";

import { useAppContext } from "fusion-context";
import { buildCanonicalUrlWPartnerRedirect } from "../utilities/wapo-canonical-url";
import { getLanguage } from "../utilities/ans-helper";
import { isAdFree } from "../utilities/login-details";

import getHomepageMetadata from "./shared/homepage-metadata";
import { getFrontsAdConfig } from "./shared/fronts-ad-config";
import { loadZeusCommonKeyValues } from "./shared/zeus-common-key-values";
import { getSubsConfig } from "./shared/subscriptions-config";
import { preconnectDomains } from "./shared/preconnect/default";
import { loadZeusSass } from "./shared/zeus-saas.jsx";
import { loadThirdPartyScripts } from "./shared/third-party-scripts";
import { generatePermutiveSetup } from "./shared/permutive";
import loadPrivacyStub from "./shared/privacy-stub.js";
/**
 * The Head component. Provides next/head and tags that belong in the <head>.
 */
export function Head(props) {
  const {
    metaValue,
    siteProperties: { siteDomainURL },
    globalContent,
    variant
  } = props;
  /**
   * In an attempt to stop scripts from slowing down the Editor,
   * prevent them from loading with an isAdmin flag.
   *
   * These are the scripts (not all the ones above):
   * - pwapi
   * - Preconnect
   * - ZeusSaas
   * - ThirdPartyScripts (GA + GTM)
   */

  const { isAdmin } = useAppContext();
  const contentType = metaValue("contentType");
  const isFront = contentType === "front";
  const isHomepage = contentType === "homepage";
  const commercialNode = metaValue("commercial_node");
  const keywords = (!!metaValue("keywords") && metaValue("keywords")) || "";
  const canonicalUrl = buildCanonicalUrlWPartnerRedirect(props);
  const language = getLanguage(globalContent);
  const url = metaValue("canonicalUrl") || "https://washingtonpost.com/";
  const policy = "https://www.washingtonpost.com/policies-and-standards/";
  const description = metaValue("description") || "";
  const title = metaValue("title") || "";
  const ogImage =
    metaValue("social_share_image") ||
    "https://www.washingtonpost.com/pb/resources/img/twp-social-share.png";
  const frontsAdConfigKeywords = keywords ? keywords.split(",") : [];
  const pageName = metaValue("pageName");
  const section = metaValue("section");

  return (
    <React.Fragment>
      <NextHead>
        {/* Metadata, Schema, Title */}
        {(isFront || isHomepage) &&
          getHomepageMetadata(
            keywords,
            url,
            policy,
            description,
            title,
            ogImage,
            contentType
          )}
        {/* Privacy Stub must load before ads-engine and consent (OneTrust) */}
        {!isAdmin && loadPrivacyStub()}
        {(isFront || isHomepage || !isAdFree()) &&
          getFrontsAdConfig({
            contentType,
            commercialNode,
            keywords: frontsAdConfigKeywords,
            canonicalUrl
          })}
        {(isFront || isHomepage || !isAdFree()) && !isAdmin
          ? loadZeusCommonKeyValues()
          : null}
        {!isAdmin ? generatePermutiveSetup() : null}
        {getSubsConfig({
          canonicalUrl,
          commercialNode,
          contentType,
          globalContent,
          isAdmin,
          isHomepage,
          section,
          siteDomainURL
        })}
        {!isAdmin && preconnectDomains.map((domain) => domain)}
        <link itemProp="url" rel="canonical" href={canonicalUrl} />
        {/*
          read this before modifying below code
          https://docs.chartbeat.com/cbp/tracking/standard-websites/alternative-integrations-web#two-snippet-solution
        */}
        {isHomepage && (
          <script
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line max-len
              __html: `(function(){var _sf_async_config=window._sf_async_config=(window._sf_async_config||{});Object.assign(_sf_async_config,{uid:"19624",domain:"www.washingtonpost.com",flickerControl:false,useCanonical:true,useCanonicalDomain:true});})();`
            }}
          />
        )}
        {isHomepage && (
          <script
            async
            src="//static.chartbeat.com/js/chartbeat_mab.js"
          ></script>
        )}
        <link hrefLang={language} href={siteDomainURL} />
        {(isFront || isHomepage || !isAdFree()) &&
          !isAdmin &&
          loadZeusSass(variant)}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.renderingEnv = "next",
            window.Fusion = {
              metas: {
                contentType: {value: "${contentType}"},
                pageName: {value: "${pageName}"},
                section: {value: "${section}"}
              },
              template: "template/homepage",
              deployment: "alpha"
            };
            window.pageType = "${contentType}";
      `
          }}
        />
        {!isAdmin &&
          typeof window !== "undefined" &&
          loadThirdPartyScripts({ contentType })}
      </NextHead>
    </React.Fragment>
  );
}

Head.propTypes = {
  siteProperties: PropTypes.object,
  globalContent: PropTypes.object,
  renderLibraries: PropTypes.func,
  renderCss: PropTypes.func,
  metaValue: PropTypes.func,
  isAdmin: PropTypes.boolean,
  variant: PropTypes.string,
  isMobile: PropTypes.bool,
  isNoNav: PropTypes.bool
};
